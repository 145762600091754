<template>
  <div class="logs-card">
    <v-toolbar dark tile>
      <v-toolbar-title class="text-h5 font-weight-regular">
        <span> {{ title }} Logs </span>
      </v-toolbar-title>
      <v-spacer />
      <v-btn light fab small @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-progress-linear indeterminate v-if="loading" />
    <v-divider style="padding-top: 3px" v-else />

    <v-card-actions color="#eee" class="d-flex align-center justify-center">
      <div class="d-flex align-center justify-center">
        <v-switch v-model="autoload" class="ma-0 mx-2" label="Auto Load" hide-details />
        <v-switch v-model="follow" class="ma-0 mx-2" label="Auto Scroll" hide-details />
      </div>
      <v-spacer />
      <v-btn outlined @click="() => fetchLatestLog()" class="px-5">Refresh</v-btn>
    </v-card-actions>

    <v-divider />

    <div class="logs-container" ref="logsEnd">
      <div class="text-center py-15" v-if="loading && !logLines">
        <v-progress-circular indeterminate size="50" color="grey" />
        <div class="py-2">Loading</div>
      </div>
      <div class="logs-div" v-if="logLines">
        <template v-for="(line, i) in logLines">
          <div class="log-line" :key="i" v-if="i">
            <div class="line-number">{{ i }}</div>
            <div class="line-data" v-html="line" />
          </div>
        </template>
      </div>
    </div>

    <v-divider />
  </div>
</template>

<script>
export default {
  name: "ApplicationLogCard",
  props: {
    link: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: true,
    logLines: null,
    timeout: null,
    autoload: false,
    follow: true,
  }),
  watch: {
    autoload(v, o) {
      if (v !== o) this.setupautoload();
    },
  },
  mounted() {
    this.fetchLatestLog();
    this.setupautoload();
  },
  destroyed() {
    clearInterval(this.timeout);
  },
  methods: {
    setupautoload() {
      clearInterval(this.timeout);
      if (this.autoload) {
        this.fetchLatestLog();
        this.timeout = setInterval(() => this.fetchLatestLog(), 5000);
      }
    },
    async fetchLatestLog() {
      try {
        this.loading = true;
        const response = await this.$axios.get(this.link);

        this.logLines = response.data
          .replace(
            /([\d-]+ [\d:.]+) (\s*[A-Z]+) (\d+) --- \[([^\]]+)\] (\S+)(\s+):(\s*)/g,
            (_, date, level, pid, thread, group) =>
              [
                "<newline>",
                `<span class="date">${date || ""}</span> `,
                `<span class="number">${pid || ""}</span> `,
                // " --- ",
                `[<span class="thread">${thread || ""}</span>] `,
                `<span class="package">${group || ""}</span> `,
                // "&nbsp;".repeat(spaces.length) + ":",
                "<br/>",
                `<span class="level ${level.trim()}">${level.trim()}</span> `,
                // '<span class="thread">▶</span> ',
              ].join("")
          )
          .replace(/(\tat )([^(]+)\(([^)]*)(:\d+)?\)([^\r\n]*)/g, (_, at, group, claz, line, etc) =>
            [
              '<span class="error-details">',
              "&nbsp;".repeat(4) + at,
              `<span class="package">${group}</span>`,
              "(",
              `<span class="bold">${claz}</span>`,
              line ? `:<span class="number">${line}</span>` : "",
              ")",
              `<span class="etc">${etc}</span>`,
              "</span>",
            ].join("")
          )
          .replace(/\t(\.\.\. \d+ common frames omitted)/g, (_, text) =>
            [
              "&nbsp;".repeat(4), //
              `<span class="error-details">${text}</span>`,
            ].join("")
          )
          .replace(/[\r\n]{1,2}/g, "<br/>")
          .split("<newline>");

        setTimeout(() => this.scrollToBottom(), 200);
      } catch (err) {
        this.$iziToast.showError(err);
        this.$emit("close");
      } finally {
        this.loading = false;
      }
    },
    scrollToBottom() {
      if (!this.follow) return;
      const el = this.$refs.logsEnd;
      if (el) {
        el.scrollTop = el.scrollHeight;
      }
    },
  },
};
</script>

<style lang="scss">
.logs-card {
  background: white;
  position: relative;

  .logs-container {
    margin: 10px;
    border-radius: 5px;
    height: calc(100vh - 160px);
    overflow: hidden auto;
    scroll-behavior: smooth;
  }

  .logs-div {
    color: #fff;
    background-color: #232323;
    font-family: monospace;
    font-size: 14px;

    .log-line {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: flex-start;
      border-bottom: 1px solid #333;
    }

    .line-number {
      min-width: 50px;
      padding: 5px 10px;
      text-align: right;
      color: #aaa;
      background: #424242;
      border-right: 1px solid #555;
    }

    .line-data {
      min-width: calc(100% - 50px);
      white-space: pre-wrap;
      word-wrap: break-word;
      padding: 5px 10px;
    }

    .date {
      color: teal;
    }
    .level {
      color: black;
      display: inline-block;
      background: grey;
      padding: 2px 5px;
      border-radius: 2px;
      font-weight: 900;
      &.INFO {
        background: #3f9;
      }
      &.ERROR {
        background: red;
      }
      &.WARN {
        background: orange;
      }
      &.DEBUG {
        background: #39f;
      }
    }
    .number {
      display: inline-block;
      color: cadetblue;
    }
    .thread {
      color: grey;
      font-size: 0.9em;
    }
    .package {
      color: green;
    }
    .bold {
      font-weight: bold;
      color: #a5a5a5;
    }
    .error-details {
      font-style: italic;
      font-size: 0.8em;
      color: grey;
      .package {
        color: #b69;
      }
    }
  }
}
</style>
